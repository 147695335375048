.navbar
  padding 1rem
  box-shadow 0 0 0 1px rgba(0, 0, 0, 0.08)

  +dark()
    box-shadow 0 0 0 1px rgba(255, 255, 255, 0.08)

  &__inner
    display flex
    flex-direction row
    align-items center
    justify-content space-between
    max-width breakpoints.md
    margin 0 auto

  &__links
    display none

    @media screen and (min-width breakpoints.sm)
      display flex
      align-items center
      justify-content space-between
      gap 0.25rem

  &__mobile-links
    display none

    &--open
      display flex
      flex-direction column
      gap 0.25rem

    @media screen and (min-width breakpoints.sm)
      display none

  &__mobile-link
    padding 1rem
    border-radius default_radius

    &:hover
      background-color darken(bg_color, 5%)

      +dark()
        background-color lighten(bg_color_dark, 5%)

  &__mobile-options
    display flex
    gap 0.25rem

    @media screen and (min-width breakpoints.sm)
      display none

  &__button
    display block
    line-height 0 // fixes issue with extra space in the bottom of the icon
    padding 0.75rem
    background-color transparent
    border none
    border-radius default_radius

    &:hover
      background-color darken(bg_color, 5%)

      +dark()
        background-color lighten(bg_color_dark, 5%)

  &__hamburger > svg
    +dark()
      fill #fff

  &__link
    display inline-block
    padding 0.75rem

  &__logo
    display inline-block
    border-radius default_radius
    font-weight bold

