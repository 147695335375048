body
  margin 0
  line-height 1.5
  font-family default_font
  color text_color
  background-color bg_color
  min-height 100vh
  display grid
  grid-template-rows auto 1fr auto

  // Duplication of mixin dark() because, as the mixin relies on the class of the body,
  // it doesn't work for the body
  &.dark
    color text_color_dark
    background-color bg_color_dark
  &:not(.light)
    @media (prefers-color-scheme: dark)
      color text_color_dark
      background-color bg_color_dark

