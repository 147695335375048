.container
  margin 0 auto
  padding 1rem
  padding-bottom 2.5rem
  max-width breakpoints.md
  width calc(100vw - 2rem)

.container-sm
  @extend .container
  max-width breakpoints.sm
