h1
h2
h3
h4
h5
h6
p
  margin 0.75rem 0
  font-weight 400

h1
  text_scale_size 6
h2
  text_scale_size 4
h3
  text_scale_size 3
h4
  text_scale_size 2.5
h5
  text_scale_size 2
h6
  text_scale_size 1.5

small
  text_scale_size -1

a
  color primary_color
  text-decoration none

  +dark()
    color primary_color_dark

  &:hover
    text-decoration underline

hr
  border-color rgba(0, 0, 0, 0.1)
