.card-grid
  display grid
  grid-auto-rows auto
  grid-template-columns repeat(auto-fill, minmax(280px, 1fr))
  gap 1.5rem

  @media screen and (min-width breakpoints.sm)
    grid-template-columns repeat(auto-fill, minmax(320px, 1fr))


.card
  padding 1rem
  border-radius default_radius
  box-shadow:
    0 0 0 1px rgba(0 0 0 0.06)\,
    0 2px 2px rgba(0 0 0 0.04)\,
    0 4px 4px rgba(0 0 0 0.05)\,
    0 6px 6px rgba(0 0 0 0.06)

  +dark()
    box-shadow:
      0 0 0 1px rgba(255 255 255 0.06)\,
      0 2px 2px rgba(255 255 255 0.04)\,
      0 4px 4px rgba(255 255 255 0.05)\,
      0 6px 6px rgba(255 255 255 0.06)


  &__title
    text_scale_size 2
    margin-top 0
    margin-bottom 0.1rem

  &__date
    margin-top 0
    color lighten(text_color, 20%)
    text_scale_size -1

    +dark()
      color darken(text_color_dark, 20%)

  &__description
    margin 0

  &__subtitle
    margin-top 0.5rem
    margin-bottom 0.1rem
    font-weight 600
