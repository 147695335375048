.post
  &__date
    margin-top 0
    color lighten(text_color, 20%)
    text_scale_size -1

    +dark()
      color darken(text_color_dark, 20%)

  &__subtitle
    text_scale_size 3

  &__body
    h1, h2, h3, h4, h5, h6
      margin-top 1.5em
