.dark-toggle
  // Without javascript it doesn't work, so it may as well not be shown
  .no-js &
    display none

  .sun
    display none

    +dark()
      display initial

  .moon
    +dark()
      display none
