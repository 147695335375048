blockquote
  border-left primary_color 2px solid
  margin 0 30px
  padding 0 20px

  +dark()
    border-left primary_color_dark 2px solid

  & cite
    color lighten(text_color, 20%)
    text_scale_size -1

    +dark()
      color darken(text_color_dark, 20%)
