text_scale_size(exponent)
  font-size (round(mobile_text_scale ** exponent * 1000) / 1000)

  @media screen and (min-width breakpoints.sm)
    font-size (round(desktop_text_scale ** exponent * 1000) / 1000)


dark()
  // When dark mode is explicitly active
  body.dark &
    { block }

  // When neither dark or light is active (this means javascript is not enabled)
  // use dark properties if the browser prefers dark theme
  body:not(.light) &
    @media (prefers-color-scheme: dark)
      { block }
